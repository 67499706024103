import bottle from '../icons/bottle.svg';
import can from '../icons/can.svg';

export function getImage(
  systembolagetId: number,
  bottleText: string,
  hasImage: number,
) {
  if (hasImage === 1) return `https://cdn.devhops.se/${systembolagetId}.jpg`;
  //if (hasImage) return `${process.env.PUBLIC_URL}/img/${systembolagetId}.jpg`;

  if (bottleText === 'Flaska') return bottle;
  else if (bottleText === 'Burk') return can;
  else return bottle;
}

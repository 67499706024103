import React from 'react';
import {
  getTopRatedBeer,
  BeerTopRatedContent,
} from '../slices/beerRatingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducer/RootReducer';
import { getRating } from 'helpers/Formatting';
import getColor from 'helpers/ColorHelper';

const getTopRated = (beers: BeerTopRatedContent) => beers;

export default function TopRated(): JSX.Element {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTopRatedBeer());
  }, [dispatch]);

  const groups = useSelector((state: RootState) =>
    getTopRated(state.topListSlice),
  );

  return (
    <div>
      {groups.loading ? (
        <div className="has-text-centered">
          <p className="loader"></p>
        </div>
      ) : (
        <div className="masonry">
          {groups.style.map((s, i) => (
            <div className="masonry-item">
              <div className={'notification ' + getColor(s[0].style)}>
                <p className="title">{s[0].style}</p>
                <ol>
                  {s.map((b, j) => (
                    <li>
                      <h5>
                        {b.title} {b.subTitle}
                      </h5>
                      <p>{b.brewery} </p>
                      <p>
                        {getRating(b.untappdData.rating)}(
                        {b.untappdData.ratingCount})
                      </p>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

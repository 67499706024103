import { combineReducers } from '@reduxjs/toolkit';
import beerSlice from '../slices/beerSlice';
import beerInfoSlice from '../slices/beerInfoSlice';
import displayType from '../slices/displayTypeSlice';
import dateSlice from '../slices/dateSlice';
import searchSlice from '../slices/searchSlice';
import topListSlice from '../slices/beerRatingSlice';

const appReducer = combineReducers({
  beerSlice,
  displayType,
  dateSlice,
  searchSlice,
  beerInfoSlice,
  topListSlice,
});

const rootReducer = (state: any, action: any) => {
  // if (action.type === 'beerInfo/recieveBeerInfo') {
  //   if (state.beerInfo != null && state.beerInfo.loading === false)
  //     state.beerInfo = undefined;
  // }
  // if (action.type === 'date/setCurrentDate') {
  //   if (state.dateSlice != null && state.dateSlice.loading === false) {
  //     state.dateSlice = undefined;
  //   }
  // }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum DisplayType {
  ShowList = 'SHOW_LIST',
  ShowGrid = 'SHOW_GRID',
  ShowCard = 'SHOW_CARD',
  ShowDetailed = 'SHOW_DETAILED',
  ShowMasonry = 'SHOW_MASONRY',
}

function getDisplayType(): DisplayType {
  const display: DisplayType = localStorage.getItem('display') as DisplayType;
  if (display != null) {
    return display;
  }
  return DisplayType.ShowList;
}

const initialState = getDisplayType();

const displayTypeSlice = createSlice({
  name: 'displayType',
  initialState,
  reducers: {
    setDisplayType(state, action: PayloadAction<DisplayType>) {
      localStorage.setItem('display', action.payload);
      return action.payload;
    },
  },
});

export const { setDisplayType } = displayTypeSlice.actions;

export default displayTypeSlice.reducer;

import { ReleaseDays } from 'slices/dateSlice';
import moment from 'moment';

export default function getClosestDate(releaseDays: ReleaseDays): string {
  const selectedDate = localStorage.getItem('selected-date') as string;
  if (releaseDays.releaseDates.includes(selectedDate)) {
    return selectedDate;
  }
  let nearest = releaseDays.releaseDates.reduce((a, b) => {
    let aDiff = Math.abs(moment(a).unix() - moment().unix());
    let bDiff = Math.abs(moment(b).unix() - moment().unix());
    if (aDiff === bDiff) {
      return a > b ? a : b;
    } else {
      return bDiff < aDiff ? b : a;
    }
  });
  if (nearest == null) {
    nearest = releaseDays?.releaseDates[releaseDays.releaseDates.length - 1];
  }

  return nearest;
}

import React from 'react';

export default function About() {
  return (
    <div className="section">
      <h2>About</h2>
      <div className="card">
        <div className="card-header">Development + hoperations = devhops</div>
      </div>
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';
import store from './store/Store';
import './index.css';
import * as app from './App';
import { Helmet } from 'react-helmet';

function render() {
  const App = app.default;
  ReactDOM.render(
    <Provider store={store}>
      <Helmet>
        <meta property="og:title" content="DevHops" />
        <meta name="description" content="DevHops: todays beer tomorrow" />
      </Helmet>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./App', () => {
//     const newRootReducer = require('./reducer/RootReducer').default;
//     store.replaceReducer(newRootReducer);
//   });
// }

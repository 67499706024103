import React from 'react';
import { RootState } from '../../reducer/RootReducer';
import { useSelector } from 'react-redux';
import { Beer } from '../../types/beer';
import { BeerContent } from '../../slices/beerSlice';
import { DisplayType } from '../../slices/displayTypeSlice';
import BeerDetails from './display/BeerDetails';
import BeerGrid from './display/BeerGrid';
import BeerRow from './display/BeerRow';
import BeerCard from './display/BeerCard';
import BeerMasonry from './display/BeerMasonry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
interface BeerListProps {
  date: string;
}

const getBeers = (beers: BeerContent) => beers;
const getDisplayType = (display: DisplayType) => display;

function getBeerGrid(beers: Beer[]) {
  let rows: JSX.Element[] = [];
  let tiles: JSX.Element[] = [];
  beers.forEach((beer, i) => {
    tiles.push(
      <div className="tile is-parent" key={i}>
        <BeerGrid beer={beer} key={beer.id} />
      </div>,
    );
    if ((i + 1) % 4 === 0) {
      rows.push(
        <div className="tile is-ancestor" key={i}>
          {tiles}
        </div>,
      );
      tiles = [];
    }
  });
  rows.push(
    <div className="tile is-ancestor" key={1}>
      {tiles}
    </div>,
  );

  return rows;
}

function getBeerRows(beers: Beer[]) {
  let rows: JSX.Element[] = [];
  beers.forEach((beer, i) => {
    rows.push(<BeerRow beer={beer} key={beer.id} />);
  });
  return rows;
}

function getBeerCards(beers: Beer[]) {
  let rows: JSX.Element[] = [];
  let tiles: JSX.Element[] = [];
  beers.forEach((beer, i) => {
    tiles.push(
      <div className="column" key={'c' + i}>
        <BeerCard beer={beer} key={beer.id} />
      </div>,
    );
    if ((i + 1) % 3 === 0) {
      rows.push(
        <div className="columns" key={i}>
          {tiles}
        </div>,
      );
      tiles = [];
    }
  });
  rows.push(
    <div className="columns" key={'c'}>
      {tiles}
    </div>,
  );
  tiles = [];

  return rows;
}

function getBeerDetails(beers: Beer[]) {
  let rows: JSX.Element[] = [];
  let tiles: JSX.Element[] = [];
  beers.forEach((beer, i) => {
    tiles.push(
      <div className="column" key={i}>
        <BeerDetails beer={beer} key={i} />
      </div>,
    );
    if ((i + 1) % 3 === 0) {
      rows.push(
        <div className="columns" key={i}>
          {tiles}
        </div>,
      );
      tiles = [];
    }
  });
  rows.push(
    <div className="columns" key={1}>
      {tiles}
    </div>,
  );
  tiles = [];
  return rows;
}

function getBeerMasonry(beers: Beer[]) {
  let items: JSX.Element[] = [];
  let rows: JSX.Element[] = [];
  beers.forEach((beer, i) => {
    items.push(
      <div className="masonry-item" key={i}>
        <BeerMasonry beer={beer} key={beer.id} />
      </div>,
    );
  });
  rows.push(
    <div className="masonry" key="m">
      {items}
    </div>,
  );
  return rows;
}

function displayBeers(beers: Beer[], display: DisplayType) {
  switch (display) {
    case DisplayType.ShowGrid:
      return getBeerGrid(beers);
    case DisplayType.ShowList:
      return getBeerRows(beers);
    case DisplayType.ShowCard:
      return getBeerCards(beers);
    case DisplayType.ShowDetailed:
      return getBeerDetails(beers);
    case DisplayType.ShowMasonry:
      return getBeerMasonry(beers);
  }
}

export default function BeerList(): JSX.Element {
  const beers = useSelector((state: RootState) => getBeers(state.beerSlice));
  const display = useSelector((state: RootState) =>
    getDisplayType(state.displayType),
  );
  return (
    <div>
      <Helmet>
        <title>{`${beers.date} - DevHops`}</title>
        <meta name="description" content={`${beers.date} - DevHops`} />
      </Helmet>
      {beers.loading ? (
        <div className="has-text-centered">
          <p className="loader"></p>
        </div>
      ) : (
        <div>
          <div className="level submenu-level is-mobile has-m0">
            <div className="level-left">
              <div className="level-item">
                <div className="content">
                  <div className="control">
                    <div className="tags has-addons">
                      <span className="tag is-primary">
                        {beers.beers.length} Nyheter
                      </span>
                      <span className="tag is-white">
                        <a
                          className="is-link"
                          href={`https://beta.systembolaget.se/sok/?productLaunchFrom=${beers.date}&productLaunchTo=${beers.date}&categoryLevel1=Öl`}
                        >
                          <span style={{ paddingRight: 5 }}>
                            Till släpp på systembolaget
                          </span>
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <section className="section beer-list-section">
        {beers.loading ? (
          <div className="has-text-centered">
            <p className="title loader"></p>
          </div>
        ) : (
          displayBeers(beers.beers, display)
        )}
      </section>
    </div>
  );
}

import { BeerSearch } from 'slices/searchSlice';
import React from 'react';
import { Beer } from 'types/beer';
import { useSelector } from 'react-redux';
import { RootState } from 'reducer/RootReducer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LazyLoad from 'react-lazyload';
import { faBeer, faInfo, faBuilding } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';
import { getImage } from 'helpers/ImageHelper';

const searchResults = (beers: BeerSearch) => beers;
function getBeerResult(beers: Beer[]) {
  let results: JSX.Element[] = [];
  beers.forEach((beer, i) =>
    results.push(
      <div className="box" key={'s' + i}>
        <article className="media">
          <div className="media-left">
            <figure className="image is-1by2 is-64x64">
              <LazyLoad height={128} offset={196} placeholder={<Loading />}>
                <Link
                  className="level-item"
                  to={{
                    pathname: '/b/' + beer.id,
                    state: { beer },
                  }}
                >
                  <img
                    src={getImage(
                      beer.systembolagetId,
                      beer.bottleTextShort,
                      beer.imageUpdated,
                    )}
                    alt={beer.title}
                  />
                </Link>
              </LazyLoad>
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <Link
                className="media-left"
                to={{
                  pathname: '/b/' + beer.id,
                  state: { beer },
                }}
              >
                <strong>{beer.title}</strong> <small>{beer.subTitle}</small>
              </Link>
              <p>
                <small>{beer.brewery}</small>
              </p>
            </div>

            <nav className="level is-mobile">
              <div className="level-left">
                <Link
                  className="level-item"
                  to={{
                    pathname: '/beer/' + beer.id,
                    state: { beer },
                  }}
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faInfo} />
                  </span>
                </Link>
                {beer.untappdData && !beer.untappdData.didNotUpdate && (
                  <a
                    className="level-item"
                    href={`https://untappd.com/b/${beer.untappdData.beerSlug}/${beer.untappdData.beerId}`}
                  >
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faBeer} />
                    </span>
                  </a>
                )}
                <a
                  className="level-item"
                  href={`https://www.systembolaget.se/${beer.systembolagetId}`}
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                </a>
              </div>
            </nav>
          </div>
        </article>
      </div>,
    ),
  );
  return results;
}
export default function SearchResults(): JSX.Element {
  const beers = useSelector((state: RootState) =>
    searchResults(state.searchSlice),
  );
  return (
    <div>
      {beers.loading ? (
        <div className="has-text-centered">
          <p className="title loader"></p>
        </div>
      ) : (
        getBeerResult(beers.beers)
      )}
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Beer } from '../../../types/beer';
import { getImage } from 'helpers/ImageHelper';
import LazyLoad from 'react-lazyload';
import Loading from '../../Loading';

export interface BeerProps {
  beer: Beer;
}

export default function BeerCard(props: BeerProps) {
  const beer = props.beer;
  return (
    <div className="card">
      <div className="card-image">
        <LazyLoad height={128} offset={196} placeholder={<Loading />}>
          <figure className="image is-4by3 is-64x64 is-fullwidth">
            <img
              src={getImage(
                beer.systembolagetId,
                beer.bottleTextShort,
                beer.imageUpdated,
              )}
              alt={beer.title}
            />
          </figure>
        </LazyLoad>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{beer.title}</p>
            <p className="subtitle is-6">{beer.subTitle}</p>
          </div>
        </div>

        <div className="content">
          <ul>
            <li>{beer.brewery}</li>
            <li>{beer.country}</li>
            <li>{beer.style}</li>
            <li>{beer.price}</li>
            <li>{beer.volume}</li>
          </ul>
        </div>
      </div>
      <footer className="card-footer">
        <Link
          to={{
            pathname: 'b/' + beer.id,
            state: { beer },
          }}
          className="card-footer-item"
        >
          Info
        </Link>
        {beer.untappdData && (
          <a
            href={
              'https://untappd.com/b/' +
              beer.untappdData.beerSlug +
              '/' +
              beer.untappdData.beerId
            }
            className="card-footer-item"
          >
            Untappd
          </a>
        )}
        <a
          href={'https://www.systembolaget.se/' + beer.systembolagetId}
          className="card-footer-item"
        >
          Systembolaget
        </a>
      </footer>
    </div>
  );
}

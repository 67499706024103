import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBeersByDate } from '../api/beerStore';
import { AppThunk, AppDispatch } from '../store/Store';
import { Beer } from '../types/beer';
import moment from 'moment';

export interface BeerContent {
  beers: Beer[];
  loading: boolean;
  date: string;
}

const initialState: BeerContent = {
  beers: [],
  loading: true,
  date: moment().format('YYYY-MM-DD'),
};

const beerSlice = createSlice({
  name: 'beer',
  initialState,
  reducers: {
    receiveBeers(state, action: PayloadAction<BeerContent>) {
      return action.payload;
    },
    sortBeers(state, action: PayloadAction<BeerContent>) {
      action.payload.beers.sort();
    },
  },
});

export const loadBeersByDate = (date: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  const beers: BeerContent = {
    beers: await getBeersByDate(date).then((r) =>
      r.sort((b1, b2) =>
        b1.untappdData.rating > b2.untappdData.rating ? -1 : 1,
      ),
    ),
    loading: true,
    date: date,
  };

  if (beers.beers !== []) {
    beers.loading = false;
    dispatch(beerSlice.actions.receiveBeers(beers));
  }
};

export const sortBeer = (sortType: number): AppThunk => async (
  dispatch: AppDispatch,
) => {};

export const { receiveBeers } = beerSlice.actions;

export default beerSlice.reducer;

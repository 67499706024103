import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Beer } from '../../../types/beer';
import { assortmentTranslator } from 'helpers/Formatting';
import getColor from 'helpers/ColorHelper';

export interface BeerProps {
  beer: Beer;
}

export default function BeerGrid(props: BeerProps) {
  const beer = props.beer;
  return (
    <Link
      to={{
        pathname: 'b/' + beer.id,
        state: { beer },
      }}
    >
      <article
        className={'tile is-child notification ' + getColor(beer.style)}
        key={beer.id}
      >
        <p className="title">{beer.title}</p>
        <p className="subtitle">{beer.subTitle}</p>
        <p>{beer.brewery}</p>
        <p>{beer.style}</p>
        <p>{beer.beerDescription}</p>
        <p>{beer.volume}cl</p>
        <p>{beer.price}kr</p>
        <p>{beer.alcoholPercentage}%</p>
        <p>{assortmentTranslator(beer.assortment)}</p>
        <p>{moment(beer.sellStartDate).format('YYYY-MM-DD')}</p>
      </article>
    </Link>
  );
}

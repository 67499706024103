const colors: string[] = [
  'is-info',
  'is-primary',
  'is-success',
  'is-warning',
  'is-danger',
];

export default function getColor(style: string) {
  if (style != null) {
    if (
      style.toLocaleLowerCase().includes('ale') ||
      style.toLocaleLowerCase().includes('ipa')
    ) {
      return colors[0];
    } else if (
      style.toLocaleLowerCase().includes('stout') ||
      style.toLocaleLowerCase().includes('barley')
    ) {
      return colors[1];
    } else if (
      style.toLocaleLowerCase().includes('syrlig') ||
      style.toLocaleLowerCase().includes('berlin')
    ) {
      return colors[4];
    } else if (
      style.toLocaleLowerCase().includes('pilsner') ||
      style.toLocaleLowerCase().includes('lager') ||
      style.toLocaleLowerCase().includes('weizen') ||
      style.toLocaleLowerCase().includes('weiss') ||
      style.toLocaleLowerCase().includes('bock')
    ) {
      return colors[3];
    } else {
      return colors[2];
    }
  } else {
    return colors[2];
  }
}

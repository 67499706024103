import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer/RootReducer';
import { loadBeersByDate } from 'slices/beerSlice';
import { useHistory } from 'react-router-dom';

interface DateButtonProps {
  date: string;
  isToday: boolean;
  onClick: () => any;
}

export default function Datebutton({
  date,
  isToday,
  onClick,
}: DateButtonProps): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = useSelector((state: RootState) => state.dateSlice);

  const location = {
    pathname: '/' + date,
  };

  const onHandleClick = () => {
    history.push(location);
    dispatch(loadBeersByDate(date));
  };

  return (
    <button
      className={
        'button is-small ' +
        (isToday ? 'has-text-weight-bold ' : '') +
        (currentDate.currentDate === date ? 'is-dark is-active ' : '')
      }
      disabled={currentDate.currentDate === date}
      onClick={onHandleClick}
    >
      {date}
    </button>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBeerById } from '../api/beerStore';
import { AppThunk, AppDispatch } from '../store/Store';
import { Beer } from '../types/beer';

export interface BeerInfoContent {
  beer: Beer;
  loading: boolean;
}

const initialState: BeerInfoContent = {
  beer: {} as Beer,
  loading: true,
};

const beerInfoSlice = createSlice({
  name: 'beerInfo',
  initialState,
  reducers: {
    recieveBeerInfo(state, action: PayloadAction<BeerInfoContent>) {
      return action.payload;
    },
  },
});

export const getBeerInfo = (id: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  const beerInfo: BeerInfoContent = {
    beer: await getBeerById(id),
    loading: true,
  };

  beerInfo.loading = false;
  dispatch(beerInfoSlice.actions.recieveBeerInfo(beerInfo));
};

export const sortBeer = (sortType: number): AppThunk => async (
  dispatch: AppDispatch,
) => {};

export const { recieveBeerInfo } = beerInfoSlice.actions;

export default beerInfoSlice.reducer;

import axios from 'axios';
import { Beer } from 'types/beer';
import moment from 'moment';

export async function getBeersByDate(date: string): Promise<Beer[]> {
  const response = await axios
    .get(
      // `https://localhost:44322/api/beers/GetByDate?date=${date}`,
      `https://api.devhops.se/api/GetBeersByDate?date=${date}`,
    )
    .then((res) => res.data.map((b: Beer) => b));

  return response;
}

export async function getBeerById(id: string): Promise<Beer> {
  const response = await axios.get(
    `https://api.devhops.se/api/GetBeerById?id=${id}`,
  );

  return response.data;
}

export async function getTopRated(): Promise<Beer[][]> {
  const response = await axios.get(`https://api.devhops.se/api/GetTopRated`);

  return response.data;
}

export async function getReleaseDays(fromDate: string): Promise<string[]> {
  const response: string[] = await axios
    // .get(`https://localhost:44322/api/beers/GetReleaseDays?date=${fromDate}`)
    .get(`https://api.devhops.se/api/GetReleaseDays?date=${fromDate}`)
    .then((res) =>
      res.data.map((d: Date) => moment(d.toString()).format('YYYY-MM-DD')),
    );
  return response;
}

export async function searchBeer(query: string): Promise<Beer[]> {
  const response = await axios.post(
    // `https://localhost:44322/api/beers/SearchBeer?q=${query}`,
    `https://api.devhops.se/api/SearchBeer?q=${query}`,
  );
  return response.data;
}

export async function getUntappdData(id: number): Promise<boolean> {
  const response = await axios
    .get(`https://localhost:44322/api/beers/GetUntappdData?id=${id}`)
    .then((res) => res.data);

  if (response !== '') return true;
  return false;
}

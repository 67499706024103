import React from 'react';
import moment from 'moment';
import { Beer } from '../../../types/beer';
import {
  assortmentTranslator,
  getPrice,
  getAbv,
  getVolume,
  getRating,
} from 'helpers/Formatting';
import getColor from 'helpers/ColorHelper';
import { Link } from 'react-router-dom';

export interface BeerProps {
  beer: Beer;
}

export default function BeerMasonry(props: BeerProps) {
  const beer = props.beer;
  return (
    <Link
      to={{
        pathname: 'b/' + beer.id,
        state: { beer },
      }}
    >
      <div className={'notification ' + getColor(beer.style)} key={beer.id}>
        <p className="title">{beer.title}</p>
        <p className="subtitle">
          {beer.subTitle !== beer.brewery && beer.subTitle}
        </p>
        <p>{beer.brewery}</p>
        <p>{beer.style}</p>
        {beer.untappdData.rating !== 0 && (
          <p>
            {getRating(beer.untappdData.rating)}({beer.untappdData.ratingCount})
          </p>
        )}
        <p>{getVolume(beer.volume)}</p>
        <p>{getPrice(beer.price)}</p>
        <p>{getAbv(beer.alcoholPercentage)}</p>
        <p>{assortmentTranslator(beer.assortment)}</p>
        <p>{moment(beer.sellStartDate).format('YYYY-MM-DD')}</p>
      </div>
    </Link>
  );
}

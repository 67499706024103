import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchBeer } from '../api/beerStore';
import { AppThunk, AppDispatch } from '../store/Store';
import { Beer } from '../types/beer';

export interface BeerSearch {
  beers: Beer[];
  loading: boolean;
  query: string;
}

const initialState: BeerSearch = {
  beers: [],
  loading: true,
  query: '',
};

const beerSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchResults(state, action: PayloadAction<BeerSearch>) {
      return action.payload;
    },
  },
});

export const loadBeerSearchResults = (query: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  const beers: BeerSearch = {
    beers: await searchBeer(query),
    loading: true,
    query: query,
  };
  beers.loading = false;
  dispatch(beerSlice.actions.searchResults(beers));
};

export const { searchResults } = beerSlice.actions;

export default beerSlice.reducer;

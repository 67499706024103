import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer/RootReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { setDisplayType, DisplayType } from 'slices/displayTypeSlice';

interface DisplayButtonProps {
  displayType: DisplayType;
  icon: IconDefinition;
  onClick: () => any;
}

export default function DisplayButton({
  displayType,
  icon,
  onClick,
}: DisplayButtonProps): JSX.Element {
  const dispatch = useDispatch();

  const currentDisplayType = useSelector(
    (state: RootState) => state.displayType,
  );

  return (
    <p className="control">
      <button
        className="button is-small"
        disabled={currentDisplayType === displayType}
        onClick={() => dispatch(setDisplayType(displayType))}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    </p>
  );
}

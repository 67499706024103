import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from 'store/Store';
import { getReleaseDays } from '../api/beerStore';
import getClosestDate from 'helpers/GetClosestDate';
import { loadBeersByDate } from './beerSlice';

export interface ReleaseDays {
  releaseDates: string[];
  currentDate: string;
  loading: boolean;
}

function getSelectedDate(): string {
  const selectedDate: string = localStorage.getItem('selected-date') as string;
  if (selectedDate != null) {
    return selectedDate;
  }
  return '';
}

const initialState: ReleaseDays = {
  releaseDates: [],
  currentDate: getSelectedDate(),
  loading: true,
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setCurrentDate(state, action: PayloadAction<string>) {
      state.loading = true;
      localStorage.setItem('selected-date', action.payload);
      state.currentDate = action.payload;
      return state;
    },
    getDates(state, action: PayloadAction<ReleaseDays>) {
      return action.payload;
    },
  },
});

export const loadReleaseDays = (date: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  await getReleaseDays(date)
    .then((res) => {
      return {
        releaseDates: res,
        currentDate: date,
        loading: false,
      } as ReleaseDays;
    })
    .then((days) => {
      dispatch(dateSlice.actions.getDates(days));
      return getClosestDate(days);
    })
    .then((date) => {
      dispatch(loadBeersByDate(date));
    });
};

export const { getDates, setCurrentDate } = dateSlice.actions;

export default dateSlice.reducer;

import React from 'react';
import { useDispatch } from 'react-redux';
import { loadBeerSearchResults } from '../../slices/searchSlice';
import { useParams } from 'react-router-dom';
import SearchResults from './SearchResults';

export default function Search(): JSX.Element {
  const { q } = useParams();
  const dispatch = useDispatch();
  if (q != null) {
    dispatch(loadBeerSearchResults(q));
  }

  return (
    <div>
      <section className="section beer-list-section">
        <SearchResults />
      </section>
    </div>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTopRated } from '../api/beerStore';
import { AppThunk, AppDispatch } from '../store/Store';
import { Beer } from '../types/beer';

export interface BeerTopRatedContent {
  style: Beer[][];
  loading: boolean;
}

const initialState: BeerTopRatedContent = {
  style: [[]],
  loading: true,
};

const topListSlice = createSlice({
  name: 'topRated',
  initialState,
  reducers: {
    recieveBeerRatings(state, action: PayloadAction<BeerTopRatedContent>) {
      return action.payload;
    },
  },
});

export const getTopRatedBeer = (): AppThunk => async (
  dispatch: AppDispatch,
) => {
  const beerTopRated: BeerTopRatedContent = {
    style: await getTopRated(),
    loading: true,
  };

  beerTopRated.loading = false;
  dispatch(topListSlice.actions.recieveBeerRatings(beerTopRated));
};

export const { recieveBeerRatings } = topListSlice.actions;

export default topListSlice.reducer;

import React from 'react';
import moment from 'moment';
import { Beer } from '../../../types/beer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  faChevronLeft,
  faBuilding,
  faBeer,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  assortmentTranslator,
  getRating,
  getVolume,
  getPrice,
  getAbv,
} from 'helpers/Formatting';
import LazyLoad from 'react-lazyload';
import Loading from '../../Loading';
import { getImage } from 'helpers/ImageHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducer/RootReducer';
import { BeerInfoContent, getBeerInfo } from 'slices/beerInfoSlice';
import { Helmet } from 'react-helmet';

interface BeerInfoProps {
  id: string;
}

const getBeer = (beerInfo: BeerInfoContent) => beerInfo;

function showBeer(beer: Beer) {
  return (
    <div className="container box">
      <div className="columns is-vcentered">
        <div className="column is-2">
          <figure className="image is-4by5 is-128x128">
            <LazyLoad height={128} offset={196} placeholder={<Loading />}>
              <img
                src={getImage(
                  beer.systembolagetId,
                  beer.bottleTextShort,
                  beer.imageUpdated,
                )}
                alt={beer.title}
              />
            </LazyLoad>
          </figure>
        </div>
        <div className="column  is-offset-1">
          <h1 className="title is-2">{beer.title}</h1>
          <h2 className="subtitle is-4">{beer.subTitle}</h2>
          <br />

          <p>{beer.style}</p>
          <p>{beer.beerDescription}</p>
          <p>{getVolume(beer.volume)}</p>
          <p>{getPrice(beer.price)}</p>
          <p>{getAbv(beer.alcoholPercentage)}</p>
          <p>{assortmentTranslator(beer.assortment)}</p>
          <p>{moment(beer.sellStartDate).format('YYYY-MM-DD')}</p>
        </div>
        {beer.untappdData && beer.untappdData.rating !== 0 && (
          <div className="column is-2">
            <div className="field">
              <label className="label">Untappd rating</label>
              <div className="control">
                <p>{getRating(beer.untappdData.rating)}</p>
              </div>
            </div>
            <div className="field">
              <label className="label">Untappd rating count</label>
              <div className="control">
                <p>{beer.untappdData.ratingCount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer className="card-footer">
        {beer.untappdData && !beer.untappdData.didNotUpdate && (
          <a
            className="card-footer-item"
            href={`https://untappd.com/b/${beer.untappdData.beerSlug}/${beer.untappdData.beerId}`}
          >
            <span className="icon is-small has-text-warning">
              <FontAwesomeIcon icon={faBeer} />
            </span>
          </a>
        )}
        <a
          className="card-footer-item"
          href={`https://www.systembolaget.se/${beer.systembolagetId}`}
        >
          <span className="icon is-small has-text-success">
            <FontAwesomeIcon icon={faBuilding} />
          </span>
        </a>
      </footer>
    </div>
  );
}

export default function BeerInfo(): JSX.Element {
  const { id } = useParams<BeerInfoProps>();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    dispatch(getBeerInfo(id));
  }, [dispatch, id]);

  const handleBack = () => {
    if (history.length !== 0) {
      history.goBack();
    }
    location.pathname = '/';
  };

  const beerInfo = useSelector((state: RootState) =>
    getBeer(state.beerInfoSlice),
  );

  return (
    <div>
      <Helmet>
        <link
          rel="icon"
          href={getImage(
            beerInfo.beer.systembolagetId,
            beerInfo.beer.bottleTextShort,
            beerInfo.beer.imageUpdated,
          )}
        />
        <title>{` ${beerInfo.beer.title} ${beerInfo.beer.subTitle} - DevHops`}</title>
        <meta
          name="description"
          content={`${beerInfo.beer.title} ${beerInfo.beer.subTitle} - DevHops`}
        />
      </Helmet>
      <section className="section submenu-section">
        <nav className="level submenu-level">
          <div className="level-left">
            <div className="field has-addons">
              <button onClick={handleBack} className="button is-small">
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </div>
          </div>
        </nav>
      </section>
      <section className="section beer-list-section">
        {beerInfo.loading ? (
          <div className="has-text-centered">
            <p className="loader"></p>
          </div>
        ) : (
          beerInfo.beer != null && showBeer(beerInfo.beer)
        )}
      </section>
    </div>
  );
}

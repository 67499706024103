import React from 'react';
import { Beer } from '../../../types/beer';
import { getPrice, getVolume, getAbv, getRating } from 'helpers/Formatting';
import LazyLoad from 'react-lazyload';
import { faInfo, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faUntappd } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../Loading';
import { getImage } from 'helpers/ImageHelper';
import getColor from '../../../helpers/ColorHelper';

export interface BeerProps {
  beer: Beer;
}

export default function BeerRow(props: BeerProps) {
  const beer = props.beer;
  return (
    <div
      className={
        'box beer-box has-text-left is-outlined is-shadowless ' +
        getColor(beer.style)
      }
    >
      <article className="media">
        <div className="media-left">
          <figure className="image beer-image is-1by2 is-32x32">
            <LazyLoad height={128} offset={196} placeholder={<Loading />}>
              <Link
                to={{
                  pathname: 'b/' + beer.id,
                  state: { beer },
                }}
              >
                <img
                  className="placeholder"
                  src={getImage(
                    beer.systembolagetId,
                    beer.bottleTextShort,
                    beer.imageUpdated,
                  )}
                  alt={beer.title}
                />
              </Link>
            </LazyLoad>
          </figure>
        </div>
        <div className="media-content">
          <div className="content">
            <nav className="level is-marginless is-mobile">
              <div className="level-left">
                <div className="level-item">
                  <Link
                    to={{
                      pathname: 'b/' + beer.id,
                      state: { beer },
                    }}
                  >
                    <div className="is-size-4">
                      <strong>{beer.title}</strong>
                    </div>
                  </Link>
                </div>
                <div className="level-item is-mobile">
                  <strong>
                    <small>
                      {beer.subTitle !== beer.brewery && beer.subTitle}
                    </small>
                  </strong>
                </div>
              </div>
              <div className="level-right">
                <div className="field has-addons">
                  <p className="control">
                    <Link
                      className="button is-outlined is-small"
                      to={{
                        pathname: '/b/' + beer.id,
                        state: { beer },
                      }}
                    >
                      <span className="icon is-small has-text-info">
                        <FontAwesomeIcon icon={faInfo} />
                      </span>
                    </Link>
                  </p>
                  {beer.untappdData && !beer.untappdData.didNotUpdate && (
                    <p className="control">
                      <a
                        className="button is-outlined is-small"
                        href={`untappd:///b/${beer.untappdData.beerSlug}/${beer.untappdData.beerId}`}
                      >
                        <span className="icon is-small has-text-warning">
                          <FontAwesomeIcon icon={faUntappd} />
                        </span>
                      </a>
                    </p>
                  )}
                  <p className="control">
                    <a
                      className="button is-outlined is-small"
                      href={`https://www.systembolaget.se/${beer.systembolagetId}`}
                    >
                      <span className="icon is-small has-text-success">
                        <FontAwesomeIcon icon={faBuilding} />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </nav>
            <div className="columns is-mobile">
              <div className="column is-one-quarter">
                <span>{beer.brewery}</span>
              </div>
              <div className="column">
                <span>{getPrice(beer.price)}</span>
              </div>
              <div className="column">
                <span>{getAbv(beer.alcoholPercentage)}</span>
              </div>
              <div className="column is-hidden-mobile">
                <span>{getVolume(beer.volume)}</span>
              </div>
              <div className="column is-hidden-mobile">
                <span>{beer.bottleTextShort}</span>
              </div>
              <div className="column is-hidden-mobile">
                {beer.untappdData.rating !== 0 && (
                  <span>
                    {getRating(beer.untappdData.rating)} (
                    {beer.untappdData.ratingCount})
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

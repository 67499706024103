export function assortmentTranslator(assortment: string) {
  if (assortment === 'BS') {
    return 'Beställningssortiment';
  } else if (assortment === 'TSLS') {
    return 'Lokalt & småskaligt';
  } else if (assortment === 'FS' || assortment === 'FSB') {
    return 'Fasta sortimentet';
  } else if (assortment === 'TSE') {
    return 'Tillfälligt sortiment';
  }
}

export function getPrice(price: number): string {
  return `${(Math.round(price * 100) / 100).toFixed(2)} kr`;
}

export function getVolume(volume: number): string {
  return `${volume / 10} cl`;
}

export function getAbv(alcohol: number): string {
  return `${alcohol} %`;
}

export function getRating(rating: number): string {
  return (Math.round(rating * 100) / 100).toFixed(2);
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DisplayButton from './Displaybutton';
import { faBars, faThLarge, faTh } from '@fortawesome/free-solid-svg-icons';
import { DisplayType, setDisplayType } from 'slices/displayTypeSlice';
import { ReleaseDays, setCurrentDate } from 'slices/dateSlice';
import Datebutton from './DateButton';
import { RootState } from 'reducer/RootReducer';
import moment from 'moment';

const getDays = (days: ReleaseDays) => days;

function isToday(day: string): boolean {
  if (day === moment().format('YYYY-MM-DD')) {
    return true;
  }
  return false;
}

export default function SubMenu(): JSX.Element {
  const dispatch = useDispatch();

  const days = useSelector((state: RootState) => getDays(state.dateSlice));
  return (
    <section className="section submenu-section is-rounded">
      <nav className="level submenu-level">
        <div className="level-left">
          <div className="level-item">
            <div className="buttons has-addons">
              {days.releaseDates &&
                days.releaseDates.map((day, index) => (
                  <Datebutton
                    date={day}
                    key={index}
                    isToday={isToday(day)}
                    onClick={() => dispatch(setCurrentDate(day))}
                  ></Datebutton>
                ))}
            </div>
          </div>
          <div className="level-item">
            {days.loading && <div className="loader is-pink"></div>}
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="field has-addons">
              <DisplayButton
                displayType={DisplayType.ShowList}
                icon={faBars}
                onClick={() => dispatch(setDisplayType(DisplayType.ShowList))}
              />
              <DisplayButton
                displayType={DisplayType.ShowMasonry}
                icon={faTh}
                onClick={() =>
                  dispatch(setDisplayType(DisplayType.ShowMasonry))
                }
              />
              <DisplayButton
                displayType={DisplayType.ShowDetailed}
                icon={faThLarge}
                onClick={() =>
                  dispatch(setDisplayType(DisplayType.ShowDetailed))
                }
              />
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}

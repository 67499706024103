import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface AppHeaderProps {
  searchText: string;
  onChange: () => any;
}

export function AppHeader() {
  const [searchText, setValue] = useState<string>('');
  const history = useHistory();
  const [isActive, setisActive] = React.useState(false);
  const handleSubmit = (e: any) => {
    if (e.key !== 'Enter') {
      return;
    }
    e.preventDefault();
    history.push(`/search/${searchText}`);
  };

  return (
    <nav
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item brand" to="/">
          DevHops
        </Link>
        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          href="#/"
          role="button"
          className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-main"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbar-main"
        className={`navbar-menu ${isActive ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          <Link to="/" className="navbar-item">
            Beer
          </Link>
          <div className="navbar-item">
            <div className="field has-addons">
              <div className="control ">
                <input
                  value={searchText}
                  onChange={(e) => setValue(e.target.value)}
                  className="input has-text-dark"
                  id="search-query"
                  type="text"
                  placeholder="Search"
                  onKeyPress={handleSubmit}
                />
              </div>
              <div className="control is-outlined">
                <button
                  type="submit"
                  className="button"
                  onSubmit={handleSubmit}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-end">
          <NavLink to="/about" className="navbar-item">
            <FontAwesomeIcon icon={faInfoCircle} />
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default AppHeader;

import React from 'react';
import BeerList from './beerList/BeerList';
import SubMenu from 'features/submenu/Submenu';
import { useDispatch } from 'react-redux';
import { loadReleaseDays, setCurrentDate } from 'slices/dateSlice';
import moment from 'moment';
import { useParams } from 'react-router-dom';

interface BeerListProps {
  date: string;
}

export default function ReleaseList(): JSX.Element {
  const { date } = useParams<BeerListProps>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (window.location.pathname === '/') {
      dispatch(loadReleaseDays(moment().format('YYYY-MM-DD')));
    } else if (date) {
      dispatch(setCurrentDate(date));
      dispatch(loadReleaseDays(date));
    }
  }, [dispatch, date]);

  return (
    <div>
      <SubMenu />
      <BeerList />
    </div>
  );
}

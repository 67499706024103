import React from 'react';
import { Link } from 'react-router-dom';
import { Beer } from '../../../types/beer';
import getColor from 'helpers/ColorHelper';
import { getPrice, getVolume, getAbv, getRating } from 'helpers/Formatting';
import LazyLoad from 'react-lazyload';
import Loading from '../../Loading';
import { getImage } from 'helpers/ImageHelper';

export interface BeerProps {
  beer: Beer;
}

export default function BeerDetails(props: BeerProps) {
  const beer = props.beer;
  return (
    <article className={'panel ' + getColor(beer.style)}>
      <div className="panel-heading">
        <p>
          {beer.title} {beer.subTitle !== beer.brewery && beer.subTitle}
        </p>
      </div>
      <div className="panel-block">
        <div className="columns is-mobile">
          <div className="column is-two-fifths">
            <figure className="image is-3by5 is-64x64 is-fullwidth">
              <LazyLoad height={128} offset={196} placeholder={<Loading />}>
                <img
                  src={getImage(
                    beer.systembolagetId,
                    beer.bottleTextShort,
                    beer.imageUpdated,
                  )}
                  alt={beer.title}
                />
              </LazyLoad>
            </figure>
          </div>
          <div className="column">
            <ul>
              <li>{beer.brewery}</li>
              <li>{beer.country}</li>
              <li>{beer.style}</li>
              <li>{getPrice(beer.price)}</li>
              <li>{getVolume(beer.volume)}</li>
              <li>{getAbv(beer.alcoholPercentage)}</li>
              {beer.untappdData.rating !== 0 && (
                <li>
                  {getRating(beer.untappdData.rating)} (
                  {beer.untappdData.ratingCount})
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="panel-block">
        <div className="level">
          <div className="level-left">
            <div className="buttons level-item">
              <Link
                to={{
                  pathname: 'b/' + beer.id,
                  state: { beer },
                }}
                className="button"
              >
                Info
              </Link>
              {beer.untappdData && !beer.untappdData.didNotUpdate && (
                <a
                  href={`https://untappd.com/b/${beer.untappdData.beerSlug}/${beer.untappdData.beerId}`}
                  className="button is-warning is-outlined"
                >
                  Untappd
                </a>
              )}
              <a
                href={`https://www.systembolaget.se/${beer.systembolagetId}`}
                className="button is-success is-outlined"
              >
                Systembolaget
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

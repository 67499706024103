import React from 'react';
import AppHeader from 'features/menuheader/AppHeader';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import BeerInfo from 'features/beerList/display/BeerInfo';
import About from 'features/About';
import Search from 'features/search/Search';
import ReleaseList from './features/ReleaseList';
import TopRated from 'features/TopRated';

export default function App() {
  return (
    <BrowserRouter>
      <AppHeader />
      <div className="container">
        <Switch>
          <Route exact name="toprated" path="/top" component={TopRated} />
          <Route exact path="/:date" component={ReleaseList} />
          <Route name="beer" exact path="/b/:id" component={BeerInfo} />
          <Route name="search" path="/search/:q" component={Search} />
          <Route exact path="/about" component={About} />
          <Route exact path="/" component={ReleaseList} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
